:root {
    --primary: #1D8843;
    --secondary: #BB892D;
    --success: #0FB60B;
    --warning: #F77F00;
    --danger: #E21C1C;
  }
  
  .navbar {
      background-color: #fff;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      position: sticky;
      top: 0;
      z-index: 999;
      border-bottom: 1px solid var(--primary);
    }
    
    .navbar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
      max-width: 1500px;
    }
    
    .navbar-logo {
      color: #fff;
      justify-self: start;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
    }
    
    .fa-typo3 {
      margin-left: 0.5rem;
      font-size: 1.8rem;
    }
    
    .nav-menu {
      display: grid;
      grid-template-columns: repeat(6, auto);
      grid-gap: 10px;
      list-style: none;
      text-align: center;
      width: 70vw;
      justify-content: end;
    }
  
    .nav-item {
      height: 40px;
    }
    
    .nav-links {
      color: var(--primary);
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
    }
    
    .nav-links:hover {
      border-bottom: 2px solid var(--secondary);
      transition: all 0.2s ease-out;
      color: var(--secondary);
    }
    
    .fa-bars {
      color: var(--primary);
    }
    
    .nav-links-mobile {
      display: none;
    }
    
    .menu-icon {
      display: none;
    }
    
    @media screen and (max-width: 960px) {
      .NavbarItems {
        position: relative;
      }
  
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        justify-content: flex-start;
        transition: all 0.5s ease;
        border-top: 1px solid #fff;
      }
    
      .nav-menu.active {
        background-color: var(--primary);
        color: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        margin-top: 38px;
        padding: 0px;
    }
    
      .nav-links {
        color: #fff;
        text-align: center;
        padding: 20px;
        width: 100%;
        display: table;
      }
    
      .nav-links:hover {
        color: var(--secondary);
        border-bottom: 0px;
      }
    
      .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
      }
    
      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
      }
    
      .fa-times {
        color: var(--primary);
        font-size: 2rem;
      }
    
      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
    
      .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
      }
    }