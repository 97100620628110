:root {
    --primary: #1D8843;
    --secondary: #BB892D;
    --success: #0FB60B;
    --warning: #F77F00;
    --danger: #E21C1C;
}

.tax-filing-steps-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.tax-filing-step-item-primary, .tax-filings-service-item-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.tax-filing-step-item-primary p {
    color: #fff;
}

.tax-filing-step-title-primary {
    color: #fff;
    font-weight: bold;
}

.tax-filing-step-item-white, .tax-filings-service-item-white {
    background-color: #fff;
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.tax-filing-step-item-white p {
    color: var(--primary);
}

.tax-filing-step-title-white {
    color: var(--primary);
    font-weight: bold;
}


.tax-filing-service-title-primary {
    color: #fff;
    font-weight: bold;
}

.tax-filing-service-title-white {
    color: var(--primary);
    font-weight: bold;
}

.tax-filings-sevice-inclusion-list {
    color: #fff;
}

.fa-file-lines {
    font-size: 25px;
    color: var(--primary);
    margin-bottom: 20px;
}

.tax-filing-service-offer {
    margin-top: 100px;
    border-radius: 5px;
    background-color: rgba(29, 136, 67, 0.14);
    justify-content: center;
    align-items: center;
    padding: 50px
}