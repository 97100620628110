:root {
  --primary_color: #1d8843;
  --secondary_color: #bb892d;
  --tertiary_color: #2a2623;
  --success_color: #0fb60b;
  --warning_color: #f77f00;
  --danger_color: #e21c1c;
}

/* SIDE BAR */

.sidebar {
  background: var(--primary_color);
  color: #fff;
  /* min-height: 100vh; */
  width: 300px;
  transition: all 0.5s;

  position: relative;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 120px;
  cursor: pointer;
}

.bars:hover {
  color: var(--tertiary_color);
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link:hover {
  background: #fff;
  color: var(--tertiary_color);
  transition: all 0.5s;
}

.link.logout:hover {
  cursor: pointer !important;
}

.active {
  background: #fff;
  color: var(--tertiary_color);
}
.icon,
.link_text {
  font-size: 16px;
  font-weight: 300;
}

@media screen and (min-width: 1024px) {
  .sidebar__inner {
    position: sticky;
    top: 120px;
  }
}
