:root {
  --primary: #1d8843;
  --secondary: #bb892d;
  --success: #0fb60b;
  --warning: #f77f00;
  --danger: #e21c1c;
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Roboto";
}

label {
  font-size: 16px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #2a2623;
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.success {
  color: var(--success);
}

.warning {
  color: var(--warning);
}

.danger {
  color: var(--danger);
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.bold {
  font-weight: bold;
}

p {
  font-size: 18px;
  color: #2a2623;
  font-weight: 300;
}

li {
  font-size: 18px;
  color: #2a2623;
  font-weight: 300;
}

.justify {
  text-align: justify;
}

.ita-container {
  padding: 50px 0px;
}

.container-rounded {
  border-radius: 5px;
}

.bordered {
  border: 1px solid var(--primary);
}

.container-space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-flex {
  display: flex;
}

.mobile-header-container {
  background-image: url("../public/images/income-tax-assistant-banner-tax-advise-photo.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 70px;
}

.landing-page {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.h1--primary {
  color: var(--primary);
}

.h1--primary-mobile {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
}

.h1--secondary {
  color: var(--secondary);
}

.h2--primary {
  color: var(--primary);
  font-weight: bold;
}

.h2--primary.right {
  text-align: right;
}

.h2-tertiary {
  font-weight: bold;
  color: #2a2623;
}

.h3--header-subtext {
  color: #2a2623;
  font-weight: 300;
  font-size: 20px;
  margin-top: 20px;
}

.h3--header-subtext-mobile {
  color: #fff;
  font-weight: 300;
  margin-top: 20px;
}

.footer-container {
  background-color: #fff;
  border-top: 1px solid var(--primary);
  margin-top: 50px;
  padding-top: 50px;
}

.center {
  text-align: center;
}

.footer-column {
  margin-top: 50px;
  text-align: center;
}

.secondary-footer {
  background-color: var(--primary);
  color: #fff;
  height: 100px;
  padding-top: 25px;
  margin-top: 20px;
}

.secondary-footer.mobile {
  height: unset;
  padding: 25px 10px;
}

input,
.form-control {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--primary);
  padding: 8px;
}

.italic {
  font-style: italic;
}

.css-13cymwt-control {
  border-color: var(--primary) !important;
}

.is-invalid {
  border: 1px solid var(--danger);
}

.loading-modal-body {
  height: 250px;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.container-center {
  flex: 1;
  border: 1px solid var(--primary);
  padding: 40px;
  border-radius: 5px;
}

.text-left {
  text-align: left;
}

.text-white {
  color: #fff;
}
