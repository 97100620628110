.table td {
  vertical-align: middle;
}

.consultation-table thead th:first-of-type,
.consultation-table tbody td:first-of-type {
  display: none;
}

.table {
  overflow: scroll;
  display: block;
}
